import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { definePreset } from "@primevue/themes";
import DefaultTheme from "../../Layout/src/assets/default-theme";

import { i18n } from "./locales/i18n";
import { createPinia } from "pinia";
import { createApp } from "vue";

import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Select from "primevue/select";
import TextArea from "primevue/textarea";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import Message from "primevue/message";
import SplitButton from "primevue/splitbutton";
import Panel from "primevue/panel";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import ContextMenu from "primevue/contextmenu";
import InputNumber from "primevue/inputnumber";
import RadioButton from "primevue/radiobutton";
import ConfirmDialog from "primevue/confirmdialog";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import AutoComplete from "primevue/autocomplete";
import FileUpload from "primevue/fileupload";
import ProgressBar from "primevue/progressbar";
import InputIcon from "primevue/inputicon";
import IconField from "primevue/iconfield";
import Menu from "primevue/menu";
import Tooltip from "primevue/tooltip";
import Card from "primevue/card";

import vueDebounce from "vue-debounce";

const MyPreset = definePreset(Aura, { DefaultTheme });

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();

  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        //prefix: "m",
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });

  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("Toast", Toast);
  app.component("Checkbox", Checkbox);
  app.component("PrimeButton", Button);
  app.component("MultiSelect", MultiSelect);
  app.component("InputText", InputText);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("Select", Select);
  app.component("TextArea", TextArea);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("SplitButton", SplitButton);
  app.component("Panel", Panel);
  app.component("PrimeDialog", Dialog);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("ContextMenu", ContextMenu);
  app.component("InputNumber", InputNumber);
  app.component("RadioButton", RadioButton);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("Message", Message);
  app.component("Tag", Tag);
  app.component("PrimeTextarea", Textarea);
  app.component("AutoCompletePrime", AutoComplete);
  app.component("FileUpload", FileUpload);
  app.component("ProgressBar", ProgressBar);
  app.component("InputIcon", InputIcon);
  app.component("IconField", IconField);
  app.component("Card", Card);

  app.component("PrimeMenu", Menu);

  app.directive("debounce", vueDebounce({ lock: true }));
  app.directive("tooltip", Tooltip);
}
