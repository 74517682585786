<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t('inventory-count.import')"
    :modal="true"
    id="inventory-count-import-dialog"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="w-3/12"
  >
    <div class="formgrid grid grid-cols-12 gap-4">
      <div class="field col-span-12">
        <label for="inventory-import-warehouse">{{ t("inventory-count.warehouse") }}</label>
        <Select
          id="inventory-import-warehouse"
          v-model="selectedWarehouseId"
          :options="warehouses"
          optionLabel="name"
          optionValue="id"
          class="w-full"
          pt:list:data-testid="inventory-import-warehouse-list"
        />
      </div>
      <div class="field col-span-12">
        <FileUpload
          :multiple="false"
          :fileLimit="1"
          accept=".csv,.xlsx"
          customUpload
          :maxFileSize="1000000"
          @uploader="onUpload($event as FileUploadUploaderEvent)"
        >
          <template #header="{ chooseCallback, uploadCallback, files }">
            <div class="flex justify-between items-center w-full">
              <PrimeButton
                class="c-circular-button mr-4 c-box-shadow"
                @click="chooseCallback()"
                data-testid="c-inventory-count-file-upload"
                :disabled="files.length > 0"
              >
                <i class="pi pi-upload c-default-button c-circular-icon"></i>
                <span class="px-4">{{ t("inventory-count.choose-file") }}</span>
              </PrimeButton>

              <PrimeButton
                class="c-circular-button mr-4 c-box-shadow"
                @click="uploadCallback()"
                data-testid="c-import-inventory-count-file"
                :disabled="files.length === 0"
              >
                <ProgressSpinner class="w-8 h-8" v-if="uploadInProgress" />
                <i class="pi pi-file-import c-default-button c-circular-icon" v-else></i>
                <span class="px-4">{{ t("inventory-count.import") }}</span>
              </PrimeButton>
            </div>
          </template>
          <template #content="{ files, removeFileCallback }">
            <div v-if="files.length > 0">
              <div
                v-for="(file, index) of files"
                :key="file.name + file.type + file.size"
                class="flex justify-between items-center"
              >
                <span class="font-semibold">{{ file.name }}</span>
                <span class="font-semibold">{{ formatSize(file.size) }}</span>
                <PrimeButton icon="pi pi-times" @click="removeFileCallback(index)" outlined rounded severity="danger" />
              </div>
            </div>
          </template>
          <template #empty>
            <div class="flex justify-center">
              <div class="mr-4">
                <i class="pi pi-cloud-upload text-5xl" />
              </div>
              <div>
                <p class="m-0">{{ t("inventory-count.file-upload.choose-file") }}</p>
                <p class="m-0">{{ t("inventory-count.file-upload.valid-formats", { formats: "csv, xlsx" }) }}</p>
              </div>
            </div>
          </template>
        </FileUpload>
      </div>
    </div>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useInventoryCountService } from "../api/InventoryCountService";
import { FileUploadUploaderEvent } from "primevue/fileupload";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useAuth } from "@cumulus/event-bus";

const props = defineProps<{
  visibleDialog: boolean;
}>();

const emit = defineEmits<{
  (e: "update:visibleDialog", value: boolean): void;
}>();

const { getAllWarehouses } = useWarehouse();
const { importExcel, importCsv } = useInventoryCountService();
const toast = useCumulusToast(useToast());
const { t } = useI18n();
const { getUser } = useAuth();

const uploadInProgress = ref<boolean>(false);
const selectedWarehouseId = ref<string>("");
const warehouses = ref<Warehouse[]>([]);

const visible = computed<boolean>({
  get: () => props.visibleDialog,
  set: (value) => emit("update:visibleDialog", value),
});

const onUpload = async (event: FileUploadUploaderEvent) => {
  const files = event.files;

  if (files.length === 0) {
    return;
  }

  try {
    uploadInProgress.value = true;
    for (const file of files as File[]) {
      const fileExtension = file.name.split(".").pop();
      const user = await getUser();
      const employee = user.getEmployee();
      const formData = new FormData();
      formData.append("employeeName", employee.firstName + " " + employee.lastName);
      formData.append("employeeEmail", employee.email);
      formData.append("file", file);

      if (fileExtension === "xlsx") {
        await importExcel(selectedWarehouseId.value, formData);
      } else if (fileExtension === "csv") {
        await importCsv(selectedWarehouseId.value, formData);
      } else {
        toast.add({
          severity: "error",
          summary: t("inventory-count.file-upload.invalid-file-type"),
          detail: t("inventory-count.file-upload.invalid-file-type-details", { formats: "csv, xlsx" }),
        });
      }
    }
    visible.value = false;
    toast.add({
      severity: "success",
      summary: t("inventory-count.import-started"),
      detail: t("inventory-count.import-started-details"),
    });
  } finally {
    uploadInProgress.value = false;
  }
};

const formatSize = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = 2;
  const sizes = ["Bytes", "KB", "MB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

onMounted(async () => {
  warehouses.value = await getAllWarehouses();
  if (warehouses.value.length > 0) {
    selectedWarehouseId.value = warehouses.value[0].id;
  }
});
</script>

<style scoped lang="scss">
.c-box-shadow {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
</style>
