<template>
  <Teleport to="#c-mobile-toolbar" v-if="isMobile">
    <PrimeButton class="p-button-text" data-testid="btn-cancel-mobile">
      <i class="pi pi-times"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.cancel") }}</span>
    </PrimeButton>
    <PrimeButton class="p-button-text" data-testid="btn-save-mobile" :disabled="true">
      <i class="pi pi-check"></i>
      <span class="c-mobile-toolbar-text">{{ t("common.start") }}</span>
    </PrimeButton>
  </Teleport>

  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-4"
          data-testid="btn-save"
          @click="emit('saveClicked')"
          :disabled="!allowSave"
        >
          <ProgressSpinner class="c-spinner" v-if="isStarting" />
          <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.save") }}</span>
        </PrimeButton>
        <PrimeButton class="c-circular-button mr-4" @click="emit('cancelClicked')" data-testid="btn-cancel">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          class="c-circular-button mr-4"
          data-testid="btn-delete"
          @click="onConfirmDelete"
          :disabled="!allowDelete"
        >
          <ProgressSpinner class="c-spinner" v-if="isDeleting" />
          <i v-else class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </PrimeButton>
        <PrimeButton
          class="c-circular-button"
          data-testid="btn-start"
          @click="emit('startClicked')"
          :disabled="!allowStart"
        >
          <ProgressSpinner class="c-spinner" v-if="isStarting" />
          <i v-else class="pi pi-play c-default-button c-circular-icon"></i>
          <span class="px-4">{{ t("inventory-count.run-import") }}</span>
        </PrimeButton>

        <ConfirmPopup data-testid="inventory-count-job-delete-confirm"></ConfirmPopup>
      </div>
    </div>
  </div>
  <UnsavedChangesDialog
    position="top"
    :visible="unsavedChangesDialogVisible"
    :dialogHeader="t('common.unsaved-changes-header')"
    @cancelClicked="emit('stayOnPage')"
    @discardClicked="emit('routeToSearch')"
    @saveClicked="emit('saveClicked')"
    ><template #content>{{ t("common.unsaved-changes-text") }}</template>
    <template #cancelBtn>{{ t("common.cancel") }}</template>
    <template #discardBtn>{{ t("common.discard") }}</template>
    <template #saveBtn>{{ t("common.save") }}</template>
  </UnsavedChangesDialog>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { UnsavedChangesDialog } from "@cumulus/components";
import { useMobile } from "@/MobileService";
import { useConfirm } from "primevue/useconfirm";
import { InventoryCountStatus } from "../model/InventoryCountStatus";
import { computed } from "vue";

const { t } = useI18n();
const { isMobile } = useMobile();
const confirm = useConfirm();

const props = defineProps<{
  unsavedChangesDialogVisible: boolean;
  inventoryCountJobStatus: InventoryCountStatus;
  isDeleting: boolean;
  isStarting: boolean;
}>();

const emit = defineEmits<{
  (e: "startClicked"): void;
  (e: "saveClicked"): void;
  (e: "deleteClicked"): void;
  (e: "cancelClicked"): void;
  (e: "stayOnPage"): void;
  (e: "routeToSearch"): void;
  (e: "toggleSidebarClicked"): void;
}>();

const allowSave = computed<boolean>(() => {
  return props.inventoryCountJobStatus === InventoryCountStatus.Ready;
});

const allowStart = computed<boolean>(() => {
  return props.inventoryCountJobStatus === InventoryCountStatus.Ready;
});

const allowDelete = computed<boolean>(() => {
  return (
    props.inventoryCountJobStatus === InventoryCountStatus.Ready ||
    props.inventoryCountJobStatus === InventoryCountStatus.Failed
  );
});

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("inventory-count.delete-confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("deleteClicked");
    },
  });
};
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}
</style>
