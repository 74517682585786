<template>
  <PickingListToolbar
    @start="onStartPicking"
    @close="onClose"
    @cancel="onCancelPicking"
    @delete="confirmDelete"
    @park="onParkPicking"
    @confirm="onShowConfirmPickingDialog"
    :editMode="editMode"
    :state="pickingList?.state"
    :assignedEmployeeId="pickingList?.assignedEmployeeId"
  />
  <div class="c-picking-list" v-if="loadFailed">
    <Card>
      <template #content>
        {{ t("picking-list.load-failed") }}
      </template>
    </Card>
  </div>
  <div class="c-picking-list" v-else>
    <div v-if="isMobile" class="mb-2">
      <CumulusPanel
        toggleTestId="c-info-panel"
        :collapsed="panels.get('pickingListInfoCollapsed')"
        @onCollapsedChange="panels.set('pickingListInfoCollapsed', $event)"
      >
        <template #title>
          <div class="flex">
            <PickingListNumber :pickinglistNumber="pickingList.pickingListNumber" />
            <Tag class="ml-2" data-testid="state-tag" :value="stateComputed" :severity="(severityComputed as any)" />
          </div>
        </template>
        <template #content>
          <PickingListInformation :pickingList="pickingList" :isMobile="isMobile" />
        </template>
      </CumulusPanel>
    </div>
    <div v-else>
      <PickingListInformation
        :pickingList="pickingList"
        :isMobile="isMobile"
        :stateComputed="stateComputed"
        :severityComputed="severityComputed"
      />
    </div>

    <Card>
      <template #content>
        <Suspense>
          <PickingLinesList :pickingList="pickingList" :editMode="editMode" />
        </Suspense>
      </template>
    </Card>
  </div>
  <ConfirmDialog />

  <CheckoutDialog
    v-model:showDialog="showConfirmCheckoutDialog"
    :freightMethodId="pickingList.freightMethodId"
    :pickinglistNumber="pickingList.pickingListNumber"
    :orderNumber="pickingList.orderNumber"
    :customerNumber="pickingList.customerNumber"
    :customerName="pickingList.customerName"
    :deliverTo="pickingList.delivery"
    :agreedFreightPriceExVat="pickingList.agreedFreightPriceExVat"
    @confirm="onConfirm"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref } from "vue";
import { User, useAuth } from "@cumulus/event-bus";
import { useRoute, useRouter } from "vue-router";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { usePickingListStore } from "@/repositories/picking-list/PickingListStore";
import PickingListToolbar from "./PickingListToolbar.vue";
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { useConfirm } from "primevue/useconfirm";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import CheckoutDialog from "./checkout-dialog/CheckoutDialog.vue";
import { useToast } from "primevue/usetoast";
import { useCumulusToast, EventToastMessageOptions } from "@cumulus/toast";
import { ConfirmPickingModel } from "@/repositories/picking-list/model/ConfirmPickingModel";
import { PickingFreight } from "@/repositories/picking-list/model/PickingFreight";
import PickingLinesList from "./PickingLinesList.vue";
import PickingListInformation from "./PickingListInformation.vue";
import { useMobile } from "@/MobileService";
import { CumulusPanel } from "@cumulus/panel";
import PickingListNumber from "./checkout-dialog/components/PickingListNumber.vue";
import { NewBringBooking } from "@/goods-out/bring-integration/NewBringBooking";
import { useBring } from "@/goods-out/bring-integration/BringService";
import { Bring } from "../bring-integration/Bring";
import { Parcel } from "../bring-integration/Parcel";
import { Delivery } from "@/repositories/picking-list/model/Delivery";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { useCustomer } from "@/repositories/customer/CustomerService";
import { Customer } from "@/repositories/customer/model/Customer";
import { Client } from "@/repositories/client/model/Client";
import { useClient } from "@/repositories/client/ClientService";
import { PickupPoint } from "../bring-integration/PickupPoint";

const { t } = useI18n();
const { getClient } = useClient();
const route = useRoute();
const router = useRouter();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();
const pickingListStore = usePickingListStore();
const { pickingListById, getPickingList, deletePickingList, startPicking, parkPicking, cancelPicking, confirmPicking } =
  pickingListStore;
const pickingListId = ref<string>("");
const pickingList = ref<PickingList>(new PickingList());
const loadFailed = ref(false);
const editMode = ref(false);
const requestedEditMode = ref(false);
const showConfirmCheckoutDialog = ref(false);
const bringIntegrationService = useBring();
const { getCustomer } = useCustomer();

const { getUser } = useAuth();
const { isMobile } = useMobile();

const panels = ref(new Map<string, boolean>([["pickingListInfoCollapsed", true]]));

const onStartPicking = async () => {
  if (pickingList.value === undefined) {
    return;
  }

  const user = await getUser();
  if (
    pickingList.value.state === PickingListState.InPicking &&
    pickingList.value.assignedEmployeeId === user.getEmployee().id
  ) {
    setEditMode(true);
    return;
  }

  await startPicking(pickingList.value.id);
  setEditMode(true);
};

const onClose = () => {
  router.push({ name: "open-picking-lists" });
};

const onParkPicking = async () => {
  if (pickingList.value === undefined) {
    return;
  }

  if (pickingList.value.lines.every((line) => line.quantityPicked === null)) {
    confirm.require({
      header: t("picking-list.park-empty-picking-list-header"),
      message: t("picking-list.park-empty-picking-list-message"),
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await cancelPicking(pickingList.value.id);
        } catch {
          return;
        }

        router.push({ name: "open-picking-lists" });
      },
    });
  } else {
    confirm.require({
      header: t("picking-list.park-picking-list-header"),
      message: t("picking-list.park-picking-list-message"),
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        try {
          await parkPicking(pickingList.value.id);
        } catch {
          return;
        }

        router.push({ name: "open-picking-lists" });
      },
    });
  }
};

const onShowConfirmPickingDialog = () => {
  if (pickingList.value === undefined) {
    return;
  }

  if (pickingList.value.lines.some((line) => line.quantityPicked === null || line.quantityPicked === undefined)) {
    confirm.require({
      header: t("picking-list.confirm-all"),
      message: t("picking-list.confirm-all-detail"),
      acceptLabel: t("picking-list.confirm"),
      acceptClass: "c-btn-confirm-all",
      rejectLabel: t("common.cancel"),

      accept: () => {
        if (pickingList.value === undefined) {
          return;
        }

        pickingList.value.lines.forEach((line) => {
          if (line.quantityPicked === null || line.quantityPicked === undefined) {
            line.quantityPicked = line.quantityForPicking;
          }
        });

        showConfirmCheckoutDialog.value = true;
      },
    });
  } else {
    showConfirmCheckoutDialog.value = true;
  }
};

const onConfirm = async (
  pickingFreight: PickingFreight,
  bring: Bring | null,
  warehouse: Warehouse,
  parcels: Parcel[],
  pickupPoint: PickupPoint | null,
  shippingLabelPrinter?: string,
  packingNotePrinter?: string
) => {
  pickingList.value.freightMethodId = pickingFreight.freightMethodId;
  pickingList.value.freightMethodName = pickingFreight.freightMethodName;

  const confirmPickingRequest = new ConfirmPickingModel();
  confirmPickingRequest.pickingListId = pickingList.value.id;
  confirmPickingRequest.lines = pickingList.value.lines;
  confirmPickingRequest.freight = pickingFreight;
  confirmPickingRequest.shippingLabelPrinter = shippingLabelPrinter ?? "";
  confirmPickingRequest.packingNotePrinter = packingNotePrinter ?? "";

  if (bring != null) {
    const newBringBooking = await createBringBookingRequest(
      warehouse,
      pickingList.value.delivery,
      bring,
      parcels,
      pickupPoint
    );
    const bookingResult = await bringIntegrationService.storeBringBookingData(newBringBooking);
    pickingFreight.bringBookingDataId = bookingResult;
  }

  await confirmPicking(confirmPickingRequest);

  toast.add({
    id: "toast-confirmed-picking",
    severity: "success",
    summary: t("common.created"),
    detail: t("picking-list.confirmed-by", { initials: pickingList.value.assignedEmployeeInitials }),
    lifeEndCallback: (event: EventToastMessageOptions) => {
      if (event.message.id === "toast-confirmed-picking") {
        onClose();
      }
    },
  });
};

const createBringBookingRequest = async (
  warehouse: Warehouse,
  deliverTo: Delivery,
  bring: Bring,
  parcels: Parcel[],
  pickupPoint: PickupPoint | null
): Promise<NewBringBooking> => {
  user.value = await getUser();
  customer.value = await getCustomer(pickingList.value.customerId);
  client.value = await getClient(user.value.getClient().id);
  const employee = user.value.getEmployee();
  const recipient =
    pickupPoint == null
      ? {
          name: customer.value?.name ?? "",
          contact: {
            phoneNumber: customer.value?.phoneNumber ?? "",
            email: customer.value?.email ?? "",
            name: customer.value?.name ?? "",
          },
          addressLine: deliverTo.address.addressLines[0],
          countryCode: "NO",
          postalCode: deliverTo.address.postalCode,
          city: deliverTo.address.city,
          reference: pickingList.value.orderReference,
        }
      : {
          name: customer.value?.name ?? "",
          contact: {
            phoneNumber: customer.value?.phoneNumber ?? "",
            email: customer.value?.email ?? "",
            name: customer.value?.name ?? "",
          },
          addressLine: pickupPoint.address,
          countryCode: "NO",
          postalCode: pickupPoint.postalCode,
          city: pickupPoint.city,
          reference: pickingList.value.orderReference,
        };
  const bringBookingRequest: NewBringBooking = {
    bookBringRequest: {
      consignments: [
        {
          packages: parcels.map((parcel) => {
            return {
              correlationId: pickingList.value.id,
              weightInKg: parcel.weight,
              dimensions: {
                lengthInCm: parcel.length,
                widthInCm: parcel.width,
                heightInCm: parcel.height,
              },
            };
          }),
          parties: {
            recipient: recipient,
            sender: {
              name: client.value.name.length > 35 ? client.value.name.substring(0, 35) : client.value.name,
              contact: {
                name: employee.firstName + " " + employee.lastName,
                phoneNumber: client.value.phoneNumber,
                email: client.value.email,
              },
              addressLine: warehouse.address.addressLines[0],
              countryCode: "NO",
              city: warehouse.address.city,
              postalCode: warehouse.address.postalCode,
              reference: pickingList.value.orderNumber.toString(),
            },
            pickupPoint:
              pickupPoint != null
                ? {
                    id: pickupPoint.id,
                    countryCode: pickupPoint.countryCode,
                  }
                : null,
          },
          product: {
            additionalServices: [],
            customerNumber: bring.bringServiceAgreement.customerNumber,
            id: bring.bringShippingService.requestCode,
          },
          correlationId: pickingList.value.id,
          shippingDateTime: new Date().toISOString(),
        },
      ],
      schemaVersion: 1,
      testIndicator: true,
    },
    pickingListId: pickingList.value.id,
  };

  return bringBookingRequest;
};

const onCancelPicking = () => {
  if (pickingList.value === undefined) {
    return;
  }

  confirm.require({
    header: t("picking-list.cancel"),
    message: t("picking-list.confirm-cancel"),
    accept: async () => {
      if (pickingList.value === undefined) {
        return;
      }

      try {
        await cancelPicking(pickingList.value.id);
      } catch {
        return;
      }

      setEditMode(false);
    },
  });
};

const confirmDelete = () => {
  if (pickingList.value === undefined) {
    return;
  }

  confirm.require({
    header: t("common.confirm-delete"),
    message: t("picking-list.confirm-delete"),
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    accept: onDeletePickingList,
  });
};

const onDeletePickingList = async () => {
  if (pickingList.value === undefined) {
    return;
  }

  try {
    await deletePickingList(pickingList.value.id);
  } catch {
    return;
  }

  router.push({ name: "open-picking-lists" });
};

const toastCommonError = (errorDetail: string) => {
  toast.add({
    severity: "error",
    summary: t("common.error"),
    detail: errorDetail,
  });
};

const fetchPickingList = async () => {
  const pickingListFromStore = pickingListById(pickingListId.value);

  pickingList.value =
    pickingListFromStore?.id === pickingListId.value ? pickingListFromStore : await getPickingList(pickingListId.value);

  if (!requestedEditMode.value) return;

  if (pickingList.value.state !== PickingListState.InPicking) {
    toastCommonError(t("picking-list.already-in-picking"));
    setEditMode(false);
    return;
  }

  const user = await getUser();
  if (pickingList.value.assignedEmployeeId !== user.getEmployee().id) {
    toastCommonError(t("picking-list.in-picking-by", { initials: pickingList.value.assignedEmployeeInitials }));
    setEditMode(false);
    return;
  }

  setEditMode(true);
};

const setEditMode = (newEditMode: boolean) => {
  editMode.value = newEditMode;
  if (newEditMode) {
    router.replace({ query: { editMode: "true" } });
  } else {
    router.replace({ query: {} });
  }
};

const customer = ref<Customer>();
const client = ref<Client>();
const user = ref<User>();
onMounted(async () => {
  pickingListId.value = route.params.pickingListId as string;
  if (route.query.editMode === "true") {
    requestedEditMode.value = true;
  }
  await fetchPickingList();
});
useShortcut(ShortcutAction.cancel, onClose);

const stateComputed = computed<string>(() => {
  switch (pickingList.value.state) {
    case PickingListState.Open:
      return t("picking-list.states.open");
    case PickingListState.InPicking:
      return t("picking-list.states.inpicking-by", { initials: pickingList.value.assignedEmployeeInitials });
    case PickingListState.Parked:
      return t("picking-list.states.parked-by", { initials: pickingList.value.assignedEmployeeInitials });
    case PickingListState.Done:
      return t("picking-list.states.done");
    default:
      return "";
  }
});

const severityComputed = computed<string>(() => {
  switch (pickingList.value.state) {
    case PickingListState.Open:
      return "success";
    case PickingListState.InPicking:
      return "";
    case PickingListState.Parked:
      return "warning";
    case PickingListState.Done:
      return "info";
    default:
      return "";
  }
});
</script>

<style scoped lang="scss">
.c-picking-list {
  margin: var(--default-content-margin);
  @media (max-width: 992px) {
    margin-bottom: 6rem;
  }
}

// @media (max-width: 992px) {
//   .c-card {
//     margin: 0;
//     border-radius: 0;
//     padding: 0.5rem;
//   }
//   :deep(.c-panel) {
//     border-radius: 0;
//   }
//   :deep(.c-panel-content) {
//     padding: 0 0.2rem;
//   }
// }

:deep(.p-multiselect) {
  border: none;
}
</style>
