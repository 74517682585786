<template>
  <DataTable
    :value="lines"
    dataKey="id"
    class="c-compact-datatable"
    :paginator="true"
    :loading="loading"
    :rows="pageSize"
    :totalRecords="totalHits"
    sortField="status"
    :sortOrder="1"
    ref="searchInventoryCountList"
    :stripedRows="true"
    selectionMode="single"
    data-testid="inventory-count-job-search-result"
    :rowsPerPageOptions="[50, 100]"
    scrollable
    scrollHeight="75vh"
    :row-class="rowClass"
    :removableSort="true"
    @row-click="onRowDblClick"
    @row-select="onRowSelected"
    @page="onPageChange"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :currentPageReportTemplate="
      t('common.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
  >
    <Column field="productNumber" :header="t('inventory-count.product-number')" sortable class="text-left">
      <template #body="{ data, field, index }">
        <span :data-testid="`c-count-line-product-number-${index}`">{{ data[field] }}</span>
      </template>
    </Column>
    <Column field="productName" :header="t('inventory-count.product-name')" sortable>
      <template #body="{ data, field, index }">
        <span :data-testid="`c-count-line-product-name-${index}`">{{ data[field] }}</span>
      </template>
    </Column>
    <Column field="quantityInStock" :header="t('inventory-count.inventory')" sortable class="text-right">
      <template #body="{ data, field, index }">
        <span :data-testid="`c-count-line-inventory-${index}`">{{ data[field] }}</span>
      </template>
    </Column>
    <Column field="costPrice" :header="t('inventory-count.cost-price')" sortable class="text-right">
      <template #body="{ data, field, index }">
        <span :data-testid="`c-count-line-cost-price-${index}`">{{ n(data[field], "decimal") }}</span>
      </template>
    </Column>
    <Column field="newQuantity" :header="t('inventory-count.new-quantity')" sortable class="text-right">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          mode="decimal"
          :min="0"
          inputClass="c-row-input w-20"
          class="w-full"
          @input="onNewQuantityInput(data, index)"
          ref="newQuantityInputRef"
          @keydown.tab.prevent="tabToNewCostPrice($event)"
          :data-testid="`c-count-line-new-quantity-${index}`"
          v-if="index == editingRowIndex"
        />
        <span :data-testid="`c-count-line-new-quantity-${index}`" v-if="index !== editingRowIndex">{{
          data[field]
        }}</span>
      </template>
    </Column>
    <Column field="newCostPrice" :header="t('inventory-count.new-cost-price')" sortable class="text-right">
      <template #body="{ data, field, index }">
        <InputNumber
          v-model="data[field]"
          mode="decimal"
          :min="0"
          inputClass="c-row-input w-24"
          class="w-full"
          :minFractionDigits="2"
          :maxFractionDigits="2"
          @input="onNewCostPriceInput(data, index)"
          ref="newCostPriceInputRef"
          @keydown.tab.prevent="tabToNextRow($event)"
          :data-testid="`c-count-line-new-cost-price-${index}`"
          v-if="index == editingRowIndex"
        />
        <span :data-testid="`c-count-line-new-cost-price-${index}`" v-if="index !== editingRowIndex">{{
          n(data[field], "decimal")
        }}</span>
      </template>
    </Column>
    <Column field="diffInventory" :header="t('inventory-count.diff-inventory')" sortable class="text-right">
      <template #body="{ data, index }">
        <span :data-testid="`c-count-line-diff-inventory-${index}`">{{ getDiffInventory(data) }}</span>
      </template>
    </Column>
    <Column field="diffCostPrice" :header="t('inventory-count.diff-cost')" sortable class="text-right">
      <template #body="{ data, index }">
        <span :data-testid="`c-count-line-diff-cost-${index}`">{{ n(getDiffCostPrice(data), "decimal") }}</span>
      </template>
    </Column>
    <template #empty
      ><span data-testid="c-inventory-count-job-not-found">{{ t("inventory-count.not-found") }}</span></template
    >
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { InventoryCountLine } from "../model/InventoryCountLine";
import { ref } from "vue";
import { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { InventoryCountLineStatus } from "../model/InventoryCountLineStatus";

const { t, n } = useI18n();

const props = defineProps<{
  lines: InventoryCountLine[];
  loading: boolean;
  pageSize: number;
  totalHits: number;
}>();

const editingRowIndex = ref<number | null>(null);
const newQuantityInputRef = ref();
const newCostPriceInputRef = ref();
const selectedLine = ref<InventoryCountLine | null>(null);

const emit = defineEmits<{
  (e: "update:lines", lines: InventoryCountLine[]): void;
  (e: "failedLineClicked"): void;
}>();

const onNewQuantityInput = (line: InventoryCountLine, index: number) => {
  const updateLines = props.lines.map((l, i) => {
    if (i === index) {
      return { ...l, newQuantity: line.newQuantity };
    }
    return l;
  });

  emit("update:lines", updateLines);
};

const onPageChange = () => {
  editingRowIndex.value = null;
};

const onNewCostPriceInput = (line: InventoryCountLine, index: number) => {
  const updateLines = props.lines.map((l, i) => {
    if (i === index) {
      return { ...l, newCostPrice: line.newCostPrice };
    }
    return l;
  });

  emit("update:lines", updateLines);
};

const getDiffInventory = (line: InventoryCountLine) => line.newQuantity - line.quantityInStock;
const getDiffCostPrice = (line: InventoryCountLine) => line.newCostPrice - line.costPrice;

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  const line = event.data as InventoryCountLine;

  if (line.status === InventoryCountLineStatus.Failed) {
    emit("failedLineClicked");
    return;
  }

  editingRowIndex.value = event.index;
};

const rowClass = (line: InventoryCountLine) => {
  return {
    "c-failed-row": line.status === "Failed",
  };
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }

  selectedLine.value = event.data;
  editingRowIndex.value = event.index;
};

const tabToNewCostPrice = (event: KeyboardEvent) => {
  event.stopPropagation();

  const element = newCostPriceInputRef.value.$el as HTMLElement;
  if (element != null) {
    element.querySelector("input")?.select();
  }
};

const tabToNextRow = (event: KeyboardEvent) => {
  event.stopPropagation();

  if (editingRowIndex.value !== null) {
    editingRowIndex.value++;
  }
  const element = newQuantityInputRef.value.$el as HTMLElement;

  if (element != null) {
    element.querySelector("input")?.select();
  }
};
</script>

<style lang="scss" scoped>
:deep(.c-row-input.p-inputnumber-input) {
  --border-color: var(--select-border);
  font-size: 0.9rem;
  padding: 0.5rem;
  height: 1.8rem;
}

.c-compact-datatable :deep(.p-datatable-thead > tr > th > div) {
  display: inline-flex;
}

.c-compact-datatable :deep(.p-datatable-selectable-row.c-failed-row) {
  background-color: var(--inventory-count-job-line-failed) !important;
}
</style>
