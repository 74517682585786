<template>
  <div class="c-warehouse-list">
    <Card>
      <template #content>
        <div class="flex flex-col md:flex-row justify-end flex-wrap mb-4 mt-2">
          <div>
            <PrimeButton
              type="button"
              class="p-button-text p-button-sm w-full"
              data-testid="add-warehouse-btn"
              @click="addNewWarehouse"
            >
              <i class="c-plus"></i>
              <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
            </PrimeButton>
          </div>
        </div>

        <DataTable
          :value="warehouses"
          dataKey="id"
          :autoLayout="true"
          responsiveLayout="scroll"
          selectionMode="single"
          @row-dblclick="onRowDblClick"
          class="c-warehouse-table c-compact-datatable"
          :loading="loading"
          stripedRows
        >
          <Column
            v-for="(col, index) of selectedColumns"
            :field="col.field"
            :header="col.header"
            :key="col.field + '_' + index"
            class="pb-2"
          >
          </Column>
          <Column :header="t('warehouse.client')">
            <template #body="{ data }">
              {{ getClientName(data.clientId) }}
            </template>
          </Column>
          <Column :header="t('warehouse.state')">
            <template #body="{ data }">
              <Checkbox :value="data" v-model="activeWarehousesComputed" :disabled="true" />
            </template>
          </Column>
          <template #empty>
            <span>{{ t("warehouse.no-warehouses-found") }}</span>
          </template>
          <template #loading>
            <span v-if="loading">{{ t("warehouse.loading") }}</span>
          </template>
        </DataTable>
      </template>
    </Card>

    <WarehouseFormDialog
      v-if="showDialog"
      v-model:showDialog="showDialog"
      :warehouse="warehouse"
      @onCreateWarehouse="onCreateWarehouse"
      @onUpdateWarehouse="onUpdateWarehouse"
      @onDeleteWarehouse="onDeleteWarehouse"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";

import WarehouseFormDialog from "./WarehouseFormDialog.vue";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { DataTableRowDoubleClickEvent } from "primevue/datatable";
import { DataTableColumn } from "@cumulus/components";
import { WarehouseStates } from "@/repositories/warehouse/model/WarehouseStates";
import { useWarehouse } from "@/repositories/warehouse/WarehouseService";
import { useClientStore } from "./stores/ClientStore";
import { storeToRefs } from "pinia";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const { getAllWarehouses } = useWarehouse();
const loading = ref(false);
const loadFailed = ref(false);
const showDialog = ref(false);
const warehouses = ref<Warehouse[]>([]);
const warehouse = ref<Warehouse>(new Warehouse());
const totalHits = ref(0);
const { getAllClients } = useClientStore();
const { clients } = storeToRefs(useClientStore());

const activeWarehousesComputed = computed(() => {
  return warehouses.value.filter((warehouse) => warehouse.state === WarehouseStates.Active);
});

const columns = ref<DataTableColumn[]>([
  { field: "name", header: t("warehouse.name") },
  { field: "shortName", header: t("warehouse.shortname") },
  { field: "address.companyName", header: t("warehouse.company-name") },
  { field: "address.city", header: t("warehouse.post-office") },
  { field: "address.countryIso", header: t("warehouse.country") },
  { field: "currencyIso", header: t("warehouse.currency") },
] as DataTableColumn[]);

const selectedColumns = ref<DataTableColumn[]>(columns.value);

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const addNewWarehouse = () => {
  warehouse.value = new Warehouse();
  showDialog.value = true;
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  warehouse.value = event.data as Warehouse;
  showDialog.value = true;
};

const getClientName = (clientId: string) => {
  const client = clients.value.find((c) => c.id === clientId);
  return client ? client.name : "";
};

const onCreateWarehouse = () => {
  const itemCount = warehouses.value.length;
  waitThenReload(itemCount, 0);

  toast.add({
    severity: "success",
    summary: t("toast.add-success.summary"),
    closable: true,
  });
};

const onDeleteWarehouse = () => {
  const itemCount = warehouses.value.length;
  waitThenReload(itemCount, 0);

  toast.add({
    severity: "success",
    summary: t("toast.delete-success.summary"),
    closable: true,
  });
};

const onUpdateWarehouse = (warehouse: Warehouse) => {
  const index = warehouses.value.findIndex((p) => p.id === warehouse.id);
  warehouses.value.splice(index, 1, warehouse);

  toast.add({
    severity: "success",
    summary: t("toast.update-success.summary"),
    closable: true,
  });
};

//Wait for data to be created/deleted
const waitThenReload = (itemCount: number, retries: number) => {
  if (itemCount === warehouses.value.length) {
    timeout(500);
    loadWarehouses();

    if (retries < 4) {
      waitThenReload(itemCount, retries + 1);
    }
  }
};

const loadWarehouses = async () => {
  loading.value = true;
  try {
    warehouses.value = await getAllWarehouses();
    totalHits.value = warehouses.value.length;
  } catch {
    loadFailed.value = true;
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  getAllClients();
  loadWarehouses();
});
</script>

<style scoped lang="scss">
.c-warehouse-list {
  margin: var(--default-content-margin);
}
</style>
