{
  "print-picking-list": {
    "header": "Plukkliste",
    "no-result": "Ingen plukkliste funnet",
    "created-date": "Dato",
    "packing-note-number": "Plukkliste",
    "order-number": "Ordrenummer",
    "customer-number": "Kundenr.",
    "customer-name": "Kundenavn",
    "freight-method-name": "Frakt",
    "quantity-of-order-items": "Ordrelinjer",
    "sum-of-total-ex-vat": "Ordre verdi",
    "pdf": "PDF",
    "download-link-missing": "Kunne ikke hente pakkeliste, vennligst prøv igjen senere",
    "printers": "Skrivere",
    "print-success": "Plukkliste sendt til skriver",
    "print-success-detail": "Plukkliste blir skrevet ut til {printerName}",
    "printix": "Printix",
    "setup-printix-message": "Printix er ikke satt opp for dette firmaet. Gå hit for å sette opp {0}"
  },
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "minLength": "{property} lengde er {min}",
    "exists": "{property} finnes fra før"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "printer": "Printer",
    "select-printer": "Velg printer",
    "shipping-label-printer": "Fraktlapp printer",
    "packing-note-printer": "Pakklapp printer",
    "print": "Skriv ut",
    "restart": "Start på nytt",
    "start": "Start",
    "created": "Opprettet",
    "add": "Legg til",
    "cancel": "Avbryt",
    "ignore": "Ignorer",
    "update": "Oppdater",
    "create": "Opprett",
    "delete": "Slett",
    "close": "Lukk",
    "search": "Søk",
    "date": "Date",
    "reload": "Last inn på nytt",
    "column-chooser": "Kolonnevelger",
    "reset": "Tilbakestill kolonner",
    "loading": "Laster inn...",
    "show-message": "Vis melding",
    "save": "Lagre",
    "error": "En feil oppstod",
    "current-page-template": "Viser {first} til {last} av {totalRecords}",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text": "Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard": "Forkast",
    "save-tooltip": "Ingen endringer",
    "address": {
      "name": "Navn",
      "address": "Addresse",
      "postal-code": "Postal code",
      "city": "Post office",
      "country": "Country"
    },
    "error-detail": {
      "unknown": "Ukjent feil",
      "server-error": "Server feil",
      "not-found": "Fant ikke ressurs",
      "bad-request": "Feil i forespørsel"
    },
    "confirm-delete": "Bekreft sletting",
    "filters": {
      "filter": "Filter",
      "filter-date-from": "Fra",
      "filter-date-to": "Til",
      "filter-order-status": "Status",
      "filter-customer": "Kunde",
      "filter-customer-group": "Kunde gruppe",
      "filter-customer-number": "Kunde nummer",
      "filter-product": "Produkt",
      "filter-product-number": "Produkt nummer",
      "filter-freight": "Frakt",
      "show-filter": "Vis filter",
      "hide-filter": "Skjul filter",
      "clear-filter": "Tøm filter"
    },
    "status": {
      "open": "Åpen",
      "partiallydelivered": "Delvis levert"
    }
  },

  "inventory": {
    "no-products-found": "Ingen produkter funnet",
    "load-failed": "Kunne ikke laste produkter fra lager, vennligst prøv igjen senere",
    "load-failed-inventoried-product": "Kunne ikke laste inn produktet fra lager, vennligst prøv igjen senere",
    "productnumber": "Produktnummer",
    "productname": "Produktnavn",
    "productgroup": "Gruppenivå",
    "dimension": "Mål (H*B*D {unit})",
    "weight": "Vekt ({unit})",
    "ean": "EAN",
    "unit": "Enhet",
    "warehouse": "Lager",
    "quantity-in-inventory": "Beholdning",
    "quantity-available": "Tilgjengelig",
    "quantity-in-picking-list": "I plukkliste",
    "quantity-order-reserve": "Ordrereserve",
    "cost-price": "Kostpris",
    "total-inventory-cost": "Total lagerkost",
    "main-supplier": "Hovedleverandør",
    "main-supplier-productnumber": "Hovedleverandørs vnr",
    "advanced-search": "Avansert søk",
    "reason": "Årsak",
    "export": "Eksporter",
    "file-formats": "Filformater",
    "export-header": "Eksporter beholdning",
    "search": "Søk i beholdning",
    "change-log": {
      "label": "Endringslogg",
      "from-date": "Fra dato",
      "to-date": "Til dato",
      "show-filter": "Vis filter",
      "hide-filter": "Skjul filter",
      "inventory-changes-log": "Beholdningsendringslogg",
      "no-inventory-changes-found": "Ingen beholdningsendringer funnet",
      "product-name": "Produktnavn",
      "product-number": "Produktnummer",
      "change-date": "Endringsdato",
      "quantity": "Antall",
      "changed-date": "Endret dato",
      "change-number": "Endringsnummer",
      "batch-number": "Batchnummer",
      "total-change-value": "Total endringsverdi",
      "quantity-change": "Antall endret",
      "comment": "Kommentar",
      "reason": "Årsak",
      "cost-price": "Kostpris",
      "reasons": "Årsaker",
      "clear-filters": "Tøm filter"
    },
    "product-status": {
      "active": "Aktiv",
      "inactive": "Inaktiv",
      "discontinued": "Utgått"
    },
    "search-filters": {
      "header": "Produktfilter",
      "clear": "Tøm filter",
      "status": "Status",
      "manufacturer": "Produsent"
    },
    "header": {
      "title": "Beholdning",
      "detailed": "Detaljer",
      "inventory": "Beholdning",
      "cost": "Kostpris",
      "hide-filterbar": "Skjul filterbar",
      "show-filterbar": "Vis filterbar"
    },
    "context-menu": {
      "detailed-overview": "Detaljert oversikt",
      "change-inventory": "Endre beholdning",
      "change-cost": "Endre lagerkost",
      "copy": "Kopier"
    },
    "product-modal": {
      "overview-header": "{product} - Detaljert oversikt"
    },
    "batch": {
      "header": "Batch oversikt",
      "date-received": "Dato mottatt",
      "purchase-number": "Innkjøpsnummer",
      "batch-number": "Batchnummer",
      "quantity-received": "Antall mottatt",
      "quantity-available": "Tilgjengelig",
      "quantity-in-inventory": "Beholdning",
      "quantity-allocated": "Beholdning tildelt",
      "quantity-allocated-for-pick": "Beholdning tildelt for plukk",
      "received-cost": "Mottakspris",
      "supplier-name": "Leverandør",
      "supplier-product-number": "Lev. varenummer",
      "no-batches-found": "Ingen batcher funnet for dette produktet"
    },
    "edit-inventory": {
      "header": "{inventoryProduct} - Endre beholdning",
      "existing-inventory": "Eksisterende beholdning",
      "new-inventory": "Ny beholdning",
      "changed-value": "Endring",
      "comment": "Kommentar",
      "print": "Utskrift",
      "add-success": "Beholdningen er oppdatert!",
      "update-failed": "Kunne ikke oppdatere beholdning, lagerbeholdning har blitt endret siden du startet",
      "reason": {
        "defective": "Defekt, intern skade",
        "errorinpicklist": "Feil oppdaget under plukking",
        "inventorycount": "Varetelling",
        "returnfromcustomer": "Retur fra kunde"
      }
    },
    "edit-cost": {
      "header": "{inventoryProduct} - Endre lagerkost",
      "inventory-cost": "Lagerverdi nå",
      "unit-cost": "Enhetskost nå",
      "inventory-cost-up-down": "Lagerverdi opp/ned",
      "new-unit-cost": "Ny enhetskost",
      "add-success-inventory": "Lagerkost er oppdatert!",
      "add-success-unit": "Enhetskost er oppdatert!",
      "no-inventory-cost-change": "Ingen endring i lagerverdi!",
      "no-unit-cost-change": "Ingen endring i enhetskost!",
      "changed": {
        "new-inventory-value": "Ny lagerverdi",
        "changed-plus-minus": "Endring +/–"
      },
      "cost": {
        "totalInventoryCost": "Lagerkost",
        "unitCost": "Enhetskost"
      },
      "reason": {
        "costadjustment": "Kostjustering med egne midler",
        "wrongprice": "Feil pris v/varemottak",
        "priceprotection": "Prisbeskyttelse fra leverandør"
      }
    }
  },
  "warehouse": {
    "header": "Lager",
    "name": "Lager navn",
    "country": "Land",
    "address": "Addresse",
    "warehouse-address": "Lager addresse",
    "currencies": "Valutaer",
    "state": "Aktiv",
    "company-name": "Firmanavn",
    "shortname": "Kort navn",
    "post-office": "Poststed",
    "currency": "Valuta",
    "client": "Klient",
    "add": "Legg til varehus",
    "no-warehouses-found": "Ingen varehus funnet",
    "loading": "Henter varehus..."
  },
  "order-ready-for-picking": {
    "header": "Klar for plukking",
    "no-orders-ready-for-picking-found": "Ingen ordre klar for plukking",
    "load-failed": "Kunne ikke laste inn ordre klar for plukking, vennligst prøv igjen senere",
    "shipping-date": "Frakt dato",
    "orderNumber": "Ordrenummer",
    "freightMethod": "Fraktmetode",
    "customerNumber": "Kundenr.",
    "name": "Kundenavn",
    "orderReference": "Bestillingsref.",
    "quantity-of-order-items": "Ordrelinjer",
    "sumTotalLines": "Verdi klar til plukk",
    "context-menu": {
      "create-picking-lists": "Lag plukklister",
      "select-all": "Velg alle",
      "cancel": "Avbryt"
    },
    "confirm-create-header": "Send til plukk",
    "confirm-create-message": "Ønsker du sende alle merkede ordre til plukk?",
    "confirm-accept": "Send til plukk",
    "confirm-reject": "Avbryt",
    "created-picking-list": {
      "add-success": "Opprettet plukkliste(r)"
    },
    "websocket-error": "Liveoppdateringer er utilgjengelig",
    "websocket-error-details": "Kunne ikke opprette en tilkobling til serveren"
  },
  "picking-lines": {
    "header": "Plukklinjer"
  },
  "picking-list": {
    "header": "Plukklister",
    "no-result": "Ingen plukkliste funnet",
    "load-failed": "Kunne ikke laste inn plukkliste, vennligst prøv igjen senere",
    "header-view-picking-list": "Plukkliste",
    "shipping-date": "Frakt dato",
    "picking-list-number": "Plukkliste",
    "state": "Status",
    "employee-initials": "Bruker",
    "order-number": "Ordrenummer",
    "freight-method-name": "Fraktmetode",
    "customer-number": "Kundenr.",
    "customer-name": "Kundenavn",
    "order-reference": "Bestillingsref.",
    "quantity-of-order-items": "Ordrelinjer",
    "sum-of-total-ex-vat": "Plukkliste verdi",
    "position-number": "Posnr",
    "product-number": "Produktnummer",
    "batch-number": "Batchnummer",
    "supplier-product-number": "Lev. produktnummer",
    "gtin": "EAN nummer",
    "product-name": "Produktnavn",
    "quantity-for-picking": "I order",
    "quantity-picked": "Plukket",
    "quantity-remaining": "Rest",
    "no-row-selected": "Velg en plukkliste",
    "context-menu": {
      "start-picking": "Start plukk",
      "continue-picking": "Overta plukk",
      "show-picking-list": "Vis plukkliste",
      "delete-picking-list": "Slett plukkliste"
    },
    "confirm-delete": "Ønsker du å slette plukklisten?",
    "freight": "Frakt",
    "complete-delivery": "Samlet levering",
    "contact": "Kontaktperson",
    "deliver-to": "Leveres til:",
    "payment": "Betalingsbetingelse",
    "order-type": "Ordretype",
    "message": "Melding",
    "park": "Parker",
    "confirm": "Bekreft",
    "cancel": "Avbryt plukk",
    "confirm-cancel": "Ønsker du å avbryte plukk og tilbakestille alle linjer?",
    "already-in-picking": "Plukkliste allerede i plukk",
    "in-picking-by": "Plukkes allerede av {initials}",
    "park-picking-list-header": "Parkere plukkliste",
    "park-picking-list-message": "Ønsker du å parkere plukklisten?",
    "park-empty-picking-list-header": "Plukklisten har ingen plukkede linjer",
    "park-empty-picking-list-message": "Ingen produkter er plukket, plukklisten vil gå tilbake til åpen status. Vil du fortsette?",
    "states": {
      "open": "Åpen",
      "parked": "Parkert",
      "parked-by": "Parkert av {initials}",
      "done": "Plukket",
      "inpicking": "Plukkes",
      "inpicking-by": "Plukkes av {initials}"
    },
    "picking-started": "Plukk startet",
    "detail-picking-started": "Startet: ",
    "confirm-all-detail": "Ønsker du å bekrefte plukk på alle åpne linjer?",
    "confirm-all": "Bekreft alle",
    "confirmed-by": "Plukket av {initials}",
    "validation": {
      "package-height-missing": "Høyde mangler for pakke nummer {packageNumber}",
      "package-width-missing": "Bredde mangler for pakke nummer {packageNumber}",
      "package-length-missing": "Lengde mangler for pakke nummer {packageNumber}",
      "package-weight-missing": "Vekt mangler for pakke nummer {packageNumber}"
    }
  },
  "checkout": {
    "header": "Bekreft utmelding",
    "pickinglist-number": "Plukkliste:",
    "order-number": "Ordre:",
    "customer": "Kunde:",
    "delivery-to": "Leveres til:",
    "phone-number": "Telefon:",
    "email": "E-Post:",
    "freight-method": "Fraktmåte",
    "consignment-number": "Sendingsnummer",
    "freight-reference": "Sendingsreferanse",
    "freight-price": "Fraktbeløp",
    "freight-weight": "Vekt",
    "freight-packages": "Antall kolli",
    "confirm": "Bekreft",
    "weight": "Vekt(kg)",
    "length": "Lengde (cm)",
    "width": "Bredde (cm)",
    "height": "Høyde (cm)",
    "content": "Innhold",
    "parcel": "Pakke",
    "totalWeight": "Total vekt (kg)",
    "totalVolume": "Totalt volum (ccm)",
    "shippingCost": "Fraktkostnad",
    "shippingPrice": "Fraktpris",
    "estimateShippingPrice": "Kalkuler fraktpris",
    "error": "En feil oppstod",
    "errorGettingWarehouses": "Kunne ikke hente varehus, vennligst prøv igjen senere",
    "not-available": "Ikke tilgjengelig",
    "add-parcel": "Legg til pakke",
    "error-getting-shipping-guide": "Feil ved henting av fraktpris, vennligst prøv igjen senere"
  },
  "toast": {
    "add-success": {
      "summary": "Lager er lagt til!"
    },
    "update-success": {
      "summary": "Lager er oppdatert!"
    },
    "delete-success": {
      "summary": "Lager er slettet!"
    },
    "error": {
      "summary": "En feil oppsto",
      "unknown-error": "En ukjent feil oppsto, vennligst prøv igjen senere",
      "server-error": "En derver error, vennligst prøv igjen senere",
      "active-error": "Lageret er aktivt, sett lageret inaktivt og prøv igjen",
      "load-error": "Kunne ikke laste lagre"
    },
    "validation-error": {
      "summary": "Validering feilet",
      "detail": "Korriger alle røde felt og forsøk igjen"
    },
    "no-change": {
      "summary": "Ingen endring",
      "detail": "Ingen endring i beholdning"
    }
  },
  "delete": {
    "active-error": "Sett til inaktiv før sletting",
    "confirm-message": "Er du sikker på at du vil slette lageret?"
  }
}
